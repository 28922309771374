import * as React from "react";

import * as styles from "./Button.module.css";

type ButtonProps = {
  children: React.ReactNode;
  onClick: (ev: React.MouseEvent<HTMLButtonElement>) => void;
};

const Button = ({ children, onClick }: ButtonProps) => {
  return (
    <button className={styles.button} onClick={onClick}>
      <h4>{children}</h4>
    </button>
  );
};

export default Button;
