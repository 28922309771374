import * as React from "react";

import * as styles from "./Card.module.css";
import UndraggableImage from "./UndraggableImage";

type CardProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  bg?: JSX.Element;
  className?: string;
  backgroundImg?: string;
};

const Card = ({ children, style, backgroundImg, bg, className }: CardProps) => {
  let background: React.ReactNode;

  if (bg) {
    background = React.cloneElement(bg, { className: styles.cardBackground });
  } else if (backgroundImg) {
    background = (
      <UndraggableImage
        className={styles.cardBackground}
        src={backgroundImg}
        alt=""
      />
    );
  } else {
    background = <></>;
  }

  return (
    <div className={`${styles.card} ${className || ""}`} style={style || {}}>
      {background}
      <div className={styles.cardContent}>{children}</div>
    </div>
  );
};

export default Card;
