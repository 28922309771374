import { getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image";

export enum productCategory {
  Casa = "Casa",
  Bucato = "Bucato",
  Persona = "Persona",
}

export const productSubCategories = {};
productSubCategories[productCategory.Casa.toString()] = [
  "Cucina",
  "Piatti",
  "Bagno",
  "WC",
  "Pavimenti",
  "Multisuperfici",
];
productSubCategories[productCategory.Bucato.toString()] = [
  "Detersivi",
  "Ammorbidenti",
];
productSubCategories[productCategory.Persona.toString()] = ["Saponi"];

export enum productType {
  Spray = "Spray",
  Gel = "Gel",
  Crema = "Crema",
  Liquido = "Liquido",
  Block = "Block",
}

export enum productFunction {
  Igienizzante = "Igienizzante",
  Disinfettante = "Disinfettante",
  Detergente = "Detergente",
}

export type product = {
  id: string | number;
  name: string;
  description: string;
  imageUrl?: string;
  image?: IGatsbyImageData;
  category: productCategory;
  // TODO: how to implement subCategory better? its type should change based on the category value
  subCategory?: string;
  type?: productType;
  function?: productFunction;
};

type graphqlProductQueryResult = {
  allWpPage: {
    nodes: [
      {
        productMetadata: {
          name: string;
          description: string;
          image: {
            localFile: ImageDataLike;
            sourceUrl: string;
          };
          category: "Casa" | "Bucato" | "Persona";
          subcategorybucato: string;
          subcategorycasa: string;
          subcategorypersona: string;
          type: "Spray" | "Gel" | "Crema" | "Liquido" | "Block";
          function: string;
        };

        slug: string;
      }
    ];
  };
};

export const graphqlQueryToProducts = (
  queryResult: graphqlProductQueryResult
) => {
  return queryResult.allWpPage.nodes.map(({ slug, productMetadata }) => {
    return {
      id: slug,
      name: productMetadata.name,
      description: productMetadata.description,
      image: getImage(productMetadata.image.localFile),
      category: productCategory[productMetadata.category],
      subCategory:
        productMetadata.subcategorybucato ||
        productMetadata.subcategorycasa ||
        productMetadata.subcategorypersona,
      type: productMetadata.type,
      function: productMetadata.function,
    };
  }) as product[];
};
