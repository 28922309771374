import * as React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import * as productModel from "../model/product";
import Layout from "../components/layout/Layout";
import OverlayedImagesBanner from "../components/ui/banners/OverlayedImagesBanner";
import Seo from "../components/seo";
import ProductsList from "../components/product/ProductsList";

// TODO: remove
import DUMMY_PRODUCTS from "../model/dummy_products";

const Prodotti = ({ pageContext, data }) => {
  const [productsShown, setProductsShown] = React.useState(9);

  const allProducts = productModel
    .graphqlQueryToProducts(data)
    .concat(DUMMY_PRODUCTS); // TODO: remove

  const products = allProducts.slice(0, productsShown);

  const breadcrumbProps = {
    crumbs: pageContext.breadcrumb.crumbs,
    crumbLabel: "Prodotti",
  };

  const bannerBg = (
    <StaticImage
      src="../assets/products_banner.jpg"
      alt=""
      placeholder="blurred"
    />
  );

  const loadMoreProductsHandler = () => {
    // TODO: actual implementation
    setProductsShown(Math.min(productsShown + 9, allProducts.length));
  };

  return (
    <>
      <Seo title="Prodotti" description="Lorem ipsum dolor sit amet" />
      <Layout breadcrumbs={breadcrumbProps}>
        <OverlayedImagesBanner bg={bannerBg} />

        <ProductsList
          products={products}
          loadMoreHandler={
            allProducts.length > productsShown ? loadMoreProductsHandler : null
          }
        />
      </Layout>
    </>
  );
};

export default Prodotti;

export const query = graphql`
  query {
    allWpPage {
      nodes {
        slug
        productMetadata {
          name
          description
          category
          subcategorybucato
          subcategorycasa
          subcategorypersona
          type
          function
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            sourceUrl
          }
        }
      }
    }
  }
`;
