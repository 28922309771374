import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import * as productModel from "../../model/product";
import Card from "../ui/Card";
import * as styles from "./ProductCard.module.css";
import ProductTag from "./ProductTag";
import UndraggableImage from "../ui/UndraggableImage";

type ProductCardProps = {
  product: productModel.product;
};

const ProductCard = ({ product }: ProductCardProps) => {
  return (
    <Card className={styles.card}>
      <div className={styles.container}>
        <div className={styles.tags}>
          <ProductTag to="/404">
            {productModel.productCategory[product.category]}
          </ProductTag>
          {product.type && (
            <ProductTag to="/404">
              {productModel.productType[product.type]}
            </ProductTag>
          )}
          {product.subCategory && (
            <ProductTag to="/404">{product.subCategory}</ProductTag>
          )}
        </div>

        <Link
          to={`/prodotti/${productModel.productCategory[
            product.category
          ].toLowerCase()}/${product.id}`}
          className={styles.productData}
        >
          {
            // TODO: remove this
            product.imageUrl && (
              <UndraggableImage
                className={styles.image}
                src={product.imageUrl}
                alt={product.name}
              />
            )
          }

          {product.image && (
            <GatsbyImage
              image={product.image}
              alt={product.name}
              className={styles.image}
              objectFit="contain"
            />
          )}

          <h2 className={styles.productName}>{product.name}</h2>
          <p className={styles.productDescription}>{product.description}</p>
        </Link>
      </div>
    </Card>
  );
};

export default ProductCard;
