import * as React from "react";
import { Link } from "gatsby";

import * as styles from "./ProductTag.module.css";

type ProductTagProps = {
  children: React.ReactNode;
  to: string;
};

const ProductTag = ({ children, to }: ProductTagProps) => {
  return (
    <Link className={styles.productTag} to={to}>
      <h6>{children}</h6>
    </Link>
  );
};

export default ProductTag;
