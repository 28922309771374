import * as React from "react";
import { Link } from "gatsby";

import * as styles from "./ProductFilter.module.css";
import * as productModel from "../../model/product";
import FilterIcon from "../../assets/filter.svg";

type ProductFilterProps = {
  onChange?: () => void; // TODO: enable and set correctly
};

const buildLeftFilterColumns = () => {
  let columns = [];

  const productCategories = Object.keys(productModel.productCategory);

  productCategories.forEach(category => {
    const subType: string[] = productModel.productSubCategories[category];

    columns.push(
      <ul className={styles.filterColumn} key={`${category}-filter-column`}>
        <p className={styles.filterItem}>{category}</p>

        {subType.map(s => (
          <li
            className={styles.filterItem}
            key={`${category}-${s}-filter-item`}
          >
            <label onChange={() => console.log(s)}>
              <input type="checkbox" />
              &nbsp;{s}
            </label>
          </li>
        ))}
      </ul>
    );
  });

  return columns;
};

const buildRightFilterColumns = () => {
  const productTypes = Object.keys(productModel.productType);

  const productTypesFilter = (
    <ul className={styles.filterColumn} key={"type-filter-column"}>
      <p className={styles.filterItem}>Tipologia</p>

      {productTypes.map(t => (
        <li className={styles.filterItem} key={`type-${t}-filter-item`}>
          <label>
            <input type="checkbox" />
            &nbsp;{t}
          </label>
        </li>
      ))}
    </ul>
  );

  const productFunctions = Object.keys(productModel.productFunction);

  const productFunctionFilter = (
    <ul className={styles.filterColumn} key={"function-filter-column"}>
      <p className={styles.filterItem}>Funzione</p>

      {productFunctions.map(t => (
        <li className={styles.filterItem} key={`function-${t}-filter-item`}>
          <label>
            <input type="checkbox" />
            &nbsp;{t}
          </label>
        </li>
      ))}
    </ul>
  );

  return [productTypesFilter, productFunctionFilter];
};

const ProductFilter = ({}: ProductFilterProps) => {
  // TODO: add state management logic and onChange filter state passing to
  // parent component

  const leftColumns = buildLeftFilterColumns();
  const rightColumns = buildRightFilterColumns();

  const [filterVisible, setFilterVisible] = React.useState(false);

  const filterToggle = () => {
    setFilterVisible(!filterVisible);
  };

  return (
    <>
      <div className={styles.filterControls}>
        <Link
          className={styles.link}
          activeClassName={styles.active}
          to="/prodotti"
        >
          <h5>Tutti</h5>
        </Link>
        <Link
          className={styles.link}
          activeClassName={styles.active}
          to="/prodotti/casa"
        >
          <h5>Casa</h5>
        </Link>
        <Link
          className={styles.link}
          activeClassName={styles.active}
          to="/prodotti/bagno"
        >
          <h5>Bagno</h5>
        </Link>
        <Link
          className={styles.link}
          activeClassName={styles.active}
          to="/prodotti/bucato"
        >
          <h5>Bucato</h5>
        </Link>

        <button className={styles.toggleButton} onClick={filterToggle}>
          <h5>Filtra prodotti</h5>
          <FilterIcon className={styles.filterIcon} />
        </button>
      </div>

      {filterVisible && (
        <div className={styles.filterContainer}>
          {leftColumns}
          <div className={styles.separator}></div>
          {rightColumns}
        </div>
      )}
    </>
  );
};

export default ProductFilter;
