import * as React from "react";

import * as productModel from "../../model/product";
import StyledLink from "../ui/StyledLink";
import ProductCard from "./ProductCard";
import ProductFilter from "./ProductFilter";
import Button from "../ui/Button";
import * as styles from "./ProductsList.module.css";

type ProductsListProps = {
  products: productModel.product[];
  loadMoreHandler: () => void;
};

const ProductsList = ({ products, loadMoreHandler }: ProductsListProps) => {
  const loadMoreProductsHandler = () => {
    loadMoreHandler();
  };

  const placeholder = (
    <div className={styles.placeholder}>
      <h1>Nessun prodotto trovato</h1>
      <p>
        Non esistono prodotti che corrispondono ai criteri di ricerca
        selezionati
      </p>

      <StyledLink to="/prodotti">Vedi tutti i prodotti</StyledLink>
    </div>
  );

  return (
    <>
      <ProductFilter />

      {products.length !== 0 ? (
        <>
          <div className={styles.productList}>
            {products.map(p => (
              <ProductCard key={p.id} product={p} />
            ))}
          </div>

          {loadMoreHandler && (
            <div className={styles.buttonContainer}>
              <Button onClick={loadMoreProductsHandler}>Carica altri</Button>
            </div>
          )}
        </>
      ) : (
        placeholder
      )}
    </>
  );
};

export default ProductsList;
