import productImg1 from "../assets/lysoform_mani.png";
import productImg2 from "../assets/lysoform_bagno.png";
import productImg3 from "../assets/lysoform_persona.png";
import productImg4 from "../assets/lysoform_bucato.png";
import productImg5 from "../assets/lysoform_animali.png";

import * as productModel from "./product";

const DUMMY_PRODUCTS: productModel.product[] = [
  {
    id: 1,
    name: "product 1",
    description: "this is a product",
    imageUrl: productImg4,
    category: productModel.productCategory.Bucato,
  },
  {
    id: 2,
    name: "product 2",
    description:
      "this is another product with a longer description that spans multiple rows of text",
    imageUrl: productImg5,
    category: productModel.productCategory.Casa,
    type: productModel.productType.Spray,
  },
  {
    id: 3,
    name: "product 3",
    description: "this is a product",
    imageUrl: productImg2,
    category: productModel.productCategory.Casa,
    subCategory: "Bagno",
  },
  {
    id: 4,
    name: "product 4",
    description:
      "this is another product with a longer description that spans multiple rows of text",
    imageUrl: productImg1,
    category: productModel.productCategory.Casa,
    type: productModel.productType.Liquido,
  },
  {
    id: 5,
    name: "product 5",
    description:
      "this is another product with a longer description that spans multiple rows of text",
    imageUrl: productImg3,
    category: productModel.productCategory.Persona,
    type: productModel.productType.Liquido,
  },
];

export default DUMMY_PRODUCTS;
